import React, { Fragment } from 'react';
import { css, keyframes } from '@emotion/core';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { rem } from '../../utils/utils';
import { TextAccent } from '../../components';
import iconCheck from '../../images/icon-circle-check.svg';
import iconTrophy from '../../images/icon-trophy.svg';

const wrapperStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

/**
 * Poll creator
 */
const creatorStyles = css`
  display: flex;
  align-items: flex-start;
  margin-top: -60px;

  .emoji {
    align-self: flex-end;
    margin-right: 15px;
  }
`;

const questionStyles = ({ theme: { color } }) => css`
  width: 420px;
  background-color: ${color.white};
  border-radius: 30px 30px 30px 5px;
  box-shadow: 0 0px 25px ${color.grey900Transparent15};
  padding: 15px;
`;

/**
 * Poll voter
 */
const voterStyles = ({ resultsVisible }) => css`
  display: flex;
  align-items: flex-start;
  margin-bottom: -55px;
  align-self: flex-end;
  position: relative;

  .emoji {
    align-self: flex-end;
    margin-left: 15px;
    width: 72px;
    margin-bottom: -15px;
    opacity: ${resultsVisible ? 0 : 1};
    transition: 0.1s all ease-in-out;
  }
`;

const answerStyles = ({ theme: { color }, resultsVisible }) =>
  css`
    width: ${resultsVisible ? '510px' : '300px'};
    background-color: ${color.white};
    border-radius: 30px 30px ${resultsVisible ? '30px' : '5px'} 30px;
    box-shadow: 0 15px 25px ${color.grey900Transparent15};
    padding: 15px 15px 5px;
    min-height: 215px;
  `;

const answerFooterStyles = ({ theme: { heyPolls } }) => css`
  border-top: 1px solid ${heyPolls.border};
  background: ${heyPolls.footer};
  margin: 0 -15px -15px;
  padding: 15px;
  border-radius: 0 0 5px 30px;
`;

/**
 * Poll results
 */
const fadeInStyles = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `;

const resultsWrapperStyles = css`
  animation: 0.3s ${fadeInStyles} ease-in-out;
  opacity: 0;
  animation-fill-mode: forwards;
`;

const resultsListStyles = css`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const resultsItemStyles = ({ theme: { heyPolls } }) => css`
  padding: 10px 0;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${heyPolls.border};
  }
`;

const barWrapperStyles = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  line-height: 1;
`;

const barStyles = ({ theme: { heyPolls } }) =>
  css`
    border: 1px solid ${heyPolls.border};
    height: 12px;
    border-radius: 6px;
    flex: 1;
    overflow-x: hidden;
  `;

const animationEnterStyles = keyframes`
  from { transform: scaleX(0); }
  to { transform: scaleX(1); }
`;

const barValueStyles = ({ percentage, theme: { heyPolls } }) =>
  css`
    display: block;
    width: ${percentage}%;
    height: 10px;
    background: ${heyPolls.brand};
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    ${percentage === 100 ? 'border-radius: 6px' : null}
    transform: scaleX(0);
    animation: 0.3s ${animationEnterStyles} ease-in-out 0.15s;
    animation-fill-mode: forwards;
    transform-origin: left;
    transition: 0.15s all ease-in-out;
  `;

const barValueTextStyles = ({ theme: { heyPolls, font } }) => css`
  color: ${heyPolls.brand};
  font-size: ${rem(font.size.default)};
  margin-left: 10px;
`;

const barVoterNamesStyles = ({ theme: { color, font } }) => css`
  color: ${color.blueGrey600};
  font-size: ${rem(font.size.default)};
`;

/**
 * Buttons
 */
const buttonSubmitStyles = ({ theme: { color, heyPolls } }) => css`
  background: ${heyPolls.brand};
  border-radius: 50px;
  padding: 16px;
  color: ${color.white};
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  border: 0;
  display: block;
  width: 100%;
  &:hover {
    background: ${heyPolls.buttonHover};
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem ${heyPolls.outlineActive};
  }

  &.reset {
    position: absolute;
    bottom: -60px;
    width: 180px;
    padding: 10px;
    margin-left: 165px;
  }
`;

const buttonOptionStyles = ({ theme: { heyPolls }, selected }) =>
  css`
    background: transparent;
    border: 1px solid ${heyPolls.border};
    border-radius: 50px;
    padding: 15px 0;
    color: ${heyPolls.brand};
    font-weight: 700;
    position: relative;
    cursor: pointer;
    display: block;
    width: 100%;
    margin-bottom: 15px;
    transition: 0.15s all ease-in-out;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 0.2rem ${heyPolls.outlineFocus};
    }
    &:hover {
      border-color: ${heyPolls.brand};
    }

    ${selected &&
      `
        border: 1px solid ${heyPolls.brand};
        &:hover {
          border: 1px solid ${heyPolls.brand};
        }

        &:active,
        &:focus {
          border: 1px solid ${heyPolls.brand};
          box-shadow: 0 0 0 0.2rem ${heyPolls.outlineActive};
        }
      `}
  `;

const checkIconStyles = ({ selected }) =>
  css`
    line-height: 1;
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 10px;
    top: 11px;
    bottom: 0;
    opacity: 0;
    transform: scale(0);
    transition: 0.15s all ease-in-out;

    ${selected &&
      `
      opacity: 1;
      transform: scale(1);
    `}
  `;

const trophyIconStyles = css`
  width: 20px;
  transform: translateY(3px);
`;

/**
 * Component style declaration
 */
export const Wrapper = styled('div')(wrapperStyles);

export const Creator = styled('div')(creatorStyles);
export const QuestionBubble = styled('div')(questionStyles);
export const Voter = styled('div')(voterStyles);
export const AnswerBubble = styled('div')(answerStyles);
export const AnswerFooter = styled('footer')(answerFooterStyles);

export const ResultsWrapper = styled('div')(resultsWrapperStyles);
export const ResultsList = styled('ul')(resultsListStyles);
export const ResultsItem = styled('li')(resultsItemStyles);
const BarWrapper = styled('div')(barWrapperStyles);
const Bar = styled('span')(barStyles);
const BarValue = styled('span')(barValueStyles);
const BarValueText = styled('strong')(barValueTextStyles);
const BarVoterNames = styled('span')(barVoterNamesStyles);
const TrophyIcon = styled('img')(trophyIconStyles);

/**
 * Progress component
 */
export const Progress = ({ voters, option, percentage }) => (
  <Fragment>
    <TextAccent element="strong" weight={500} size="default">
      {option}{' '}
    </TextAccent>
    {percentage > 50 ? <TrophyIcon src={iconTrophy} alt="Trophy icon" /> : null}
    <BarWrapper>
      <Bar>
        <BarValue percentage={percentage} />
      </Bar>
      <BarValueText>
        {percentage}% ({voters.length})
      </BarValueText>
    </BarWrapper>
    <BarVoterNames>
      {voters.map((voter, index) => {
        if (index === voters.length - 1) {
          return ` and ${voter}`;
        } else if (index === voters.length - 2) {
          return voter;
        }

        return `${voter}, `;
      })}
    </BarVoterNames>
  </Fragment>
);

Progress.propTypes = {
  voters: PropTypes.array.isRequired,
  option: PropTypes.oneOf(['1:00 PM', '1:30 PM']).isRequired,
  percentage: PropTypes.number.isRequired,
};

/**
 * Button components
 */
const ButtonOptionStyles = styled('button')(buttonOptionStyles);
const CheckIcon = styled('img')(checkIconStyles);

export const ButtonOption = ({ selected, children, onClick }) => (
  <ButtonOptionStyles onClick={onClick} selected={selected}>
    <CheckIcon src={iconCheck} alt="Selected poll option" selected={selected} /> {children}
  </ButtonOptionStyles>
);

ButtonOption.propTypes = {
  selected: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export const ButtonSubmit = styled('button')(buttonSubmitStyles);
